.overflow-hide {
    overflow: hidden !important;
}
element {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

element::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
body{
    overflow: hidden;
}

.container {
    display: flex;
  }
  .item {
    flex: 1;
    margin: 1%;
    border-radius: 50%;
    background-color: gray;
    width: 20%;
    height: 20%;
  }
  .item:before {
    content: "";
    display: inline-block;
    padding-top: 100%;
  }
  