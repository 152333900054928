#versionIndicator {
  position: fixed;
  bottom: 5px;
  right: 10px;
  font-size: 12px;
  color: #2e97c8;
}

#environmentIndicator {
  width: 200px;
  background: #e43;
  position: fixed;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 3000;
}

.CircularProgressbar {
  width: 50px !important;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 25px !important;
  font-weight: 700 !important;
}

dt {
  width: 30%;
  display: inline-block;
  color: #2e97c8;
  font-weight: 500;
  padding-bottom: 2%;
  vertical-align: top;
}

dd {
  width: 60%;
  display: inline-block;
  font-weight: 300;
  padding-bottom: 2%;
}

/* check mark success */

.circle-loader {
  margin-bottom: 1.5em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #2e97c8;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #fff;
  background-color: #2e97c8;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}

.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}

input[type="email"] {
  text-transform: lowercase;
  --webkit-text-transform: lowercase;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-transform: capitalize;
}
::-moz-placeholder {
  /* Firefox 19+ */
  text-transform: capitalize;
}
:-ms-input-placeholder {
  /* IE 10+ */
  text-transform: capitalize;
}
:-moz-placeholder {
  /* Firefox 18- */
  text-transform: capitalize;
}

.PrivateNotchedOutline-root-11 {
  top: 0px !important;
}
.search .MuiOutlinedInput-notchedOutline {
  border-color: #a4a832;
}
.checkmark:after {
  opacity: 1;
  height: 1.8em;
  width: 0.75em;
  transform-origin: left top;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  content: "";
  left: 1.5em;
  top: 2.5em;
  position: absolute;
}
.multiline-autocomplete .MuiAutocomplete-inputRoot {
  max-height: unset !important;
  height: auto !important;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.65em;
    opacity: 1;
  }
  40% {
    height: 1.5em;
    width: 0.75em;
    opacity: 1;
  }
  100% {
    height: 1.8em;
    width: 0.75em;
    opacity: 1;
  }
}

div.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
div.loading .info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding: 50px;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  background: #d5d5d5;
}
.dot.positive {
  background: #4fc605 !important;
}
.dot.negative {
  background: #df0011 !important;
}
.dot.warning {
  background: #ffb300 !important;
}
.positive {
  background: #4fc60530 !important;
  color: #4fc605 !important;
}
.negative {
  background: rgba(223, 0, 17, 0.32) !important;
  color: rgba(223, 0, 17, 1) !important;
}
.text-negative {
  color: rgba(223, 0, 17, 1) !important;
}
.text-positive {
  color: #4fc605 !important;
}
.text-amber {
  color: "#FFBF00";
}
.text-warning {
  color: #ffb300 !important;
}
.button-positive {
  color: #4fc605 !important;
  border-color: #4fc605 !important;
}
.button-warning {
  color: #ffb300 !important;
  border-color: #ffb300 !important;
}
.button-negative {
  color: #df0011 !important;
  border-color: #df0011 !important;
}

.button-grey {
  color: grey !important;
  border-color: grey !important;
}

.center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.space_around {
  justify-content: space-around;
}
.align-left-space-between {
  display: flex;
  text-align: left;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-center {
  align-items: center;
}

.vertical-dots {
  height: 90%;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 10%;
  margin: 50px 0;
  border-left: 1px dashed #70707073;
}
.align-right {
  text-align: right;
}
.warning-alert {
  background: #df00110d 0% 0% no-repeat padding-box;
  border: 1px solid #df001140;
  border-radius: 5px;
}

.card-tile-secondary {
  border: 1px solid rgba(213, 213, 213, 0.88);
  border-radius: 3px;
  background-color: rgba(245, 245, 245, 0.4);
  align-items: center;
  display: flex;
  padding: 10px;
  overflow: hidden;
}

.capitalize {
  text-transform: capitalize;
}

.action-buttons {
  padding: 3% 5%;
  width: 100%;
  text-align: right;
}
.action-buttons button {
  margin-right: 20px;
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  width: 60%;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #a4a1fb;
  stroke-linecap: round;
  -webkit-transition: stroke-dashoffset 0.5s ease 0s;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #edecfe;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #404040;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}
.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #a4a1fb;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

/*Sub Navigation header*/
.top-navigation {
  margin: 1% 2%;
  width: 85%;
}
.link_button {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #26b4e8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4),
    0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4),
    0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4),
    0 1px 1px rgba(0, 0, 0, 0.2);
  background: #26b4e8;
  color: #fff;
  padding: 8px 12px;
  text-decoration: none;
}

input[name="aircraft_registration"] {
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  dt,
  dd {
    font-size: 12px;
  }

  dd {
    width: 55%;
  }
}

#kit-view-icon-id:hover {
  cursor: pointer;
}
