.layout {
  width: 350px;
}

.ggl-tooltip {
  background-color: #ffffff;
  border: 1px solid transparent;
  font-size: 14px;
  border-radius: 5px 5px 5px 5px;
}
.header {
  font-weight: 600;
  font-size: 16px;
}
.planned {
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
}
.border {
  border-top: 2px solid black;
}
.container1 {
  padding-left: 20px;
  padding-top: 6px;
}
.flight {
  padding-right: 10px;
  padding-bottom: 5px;
}
.depflight {
  padding-right: 4px;
  padding-bottom: 5px;
}
.base {
  padding-top: 18px;
  padding-right: 37px;
  padding-bottom: 5px;
}

.aircraft {
  padding-top: 18px;
  padding-right: 26px;
  padding-bottom: 5px;
}
.a_creg {
  padding-top: 18px;
  padding-right: 24px;
  padding-bottom: 5px;
}
.schedule {
  padding-right: 10px;
}

.arr_dep {
  padding-top: 18px;
  padding-right: 21px;
  padding-bottom: 5px;
}
.delay_in {
  padding-top: 18px;
  padding-right: 21px;
  padding-bottom: 5px;
}
.delay_out {
  padding-top: 18px;
  padding-right: 11px;
  padding-bottom: 5px;
}
.gnd_time {
  padding-top: 18px;
  padding-right: 15px;
  padding-bottom: 5px;
}
.man_hrs {
  padding-top: 18px;
  padding-right: 23px;
  padding-bottom: 5px;
}
.verified {
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-top: 6px;
}
.user {
  padding-left: 20px;
  padding-top: 6px;
}
.userValue {
  padding-left: 38px;
}
.image {
  height: 30px;
  float: right;
  padding-bottom: 10px;
}
.userverified {
  padding-left: 33px;
}
